"use client";

import { useRouter } from 'next/navigation';

import auth from '../auth';
import { AuthToken } from '../../../types/api/auth/AuthToken';
import dispatchGoogleAnalyticsEvent, { gaEvents, gaFormatters } from '../../analytics/google/dispatchGoogleAnalyticsEvent';

type UseRouterReturnType = ReturnType<typeof useRouter>;

export const MESSAGE_REGISTER = 'AUTH_NAVIGATE_TO_REGISTER';
export const MESSAGE_CONTACT = 'AUTH_NAVIGATE_TO_CONTACT_US';
export const MESSAGE_SUCCESSFUL_SIGN_IN = 'AUTH_SUCCESS';
export const MESSAGE_LOGIN_PRESSED = 'LOGIN_PRESSED';
export const MESSAGE_FORGOT_PASSWORD_PRESSED = 'FORGOT_PASSWORD_PRESSED';

/** Message handler for a successful sign-in message from iframe */
const handleSuccessfulSignIn = (value: AuthToken, onSubmitSuccess: () => void) => {
    auth.setAuthTokens(value);

    dispatchGoogleAnalyticsEvent(gaEvents.UserLogin, gaFormatters.userLogin(value));

    if (onSubmitSuccess) {
        onSubmitSuccess();
    }
};

/**
 * Sign in iframe message parser.
 */
const authIframeMessageParser = (
    message: string,
    value: unknown,
    onSubmitSuccess: () => void,
    router: UseRouterReturnType
): void => {
    /* eslint-disable no-case-declarations */
    switch (message) {
        case MESSAGE_REGISTER:
            // Message handler for register message from iframe
            router.push('/register');
            break;
        case MESSAGE_CONTACT:
            // Message handler for contact message from iframe
            router.push('/contact-us');
            break;
        case MESSAGE_SUCCESSFUL_SIGN_IN:
            const authToken = value as AuthToken;
            handleSuccessfulSignIn(authToken, onSubmitSuccess);
            break;
        default:
            break;
    }
    /* eslint-enable no-case-declarations */
};

export default authIframeMessageParser;
