"use client";

import get from 'lodash.get';

import { setCookie } from '../../helpers/cookie';
import { API_URL, isBrowser } from '../../config/config';
import authService from '../../data/services/auth.service';
import { localStorageClearItems, localStorageGet, localStorageRemove, localStorageSet } from '../../helpers/localStorage';
import setFlashMessage from '../../components/FlashMessage/helpers/setFlashMessage';
import { deleteFlashMessage } from '../../components/FlashMessage/helpers/deleteFlashMessage';
import sendMessageToParentFrame from '../../helpers/sendMessageToParentFrame';
import masterAnswersLookup from '../../helpers/masterAnswersLookup';
import { getQueryStringObj } from '../../helpers/getQueryString';
import { MESSAGE_CONTACT, MESSAGE_REGISTER, MESSAGE_SUCCESSFUL_SIGN_IN } from './lib/authIframeMessageParser';
import { AuthPostVariables } from '../../types/api/auth/AuthPostVariables';
import { AuthToken } from '../../types/api/auth/AuthToken';
import { deletePatientUuidFromStorage, setPatientUuidInStorage } from './helpers/patientUuid';
import { getLoginInfo } from './helpers/getLoginInfo';
import { checkLoggedIn } from './helpers/checkLoggedIn';

export const SESSION_TOKEN_LOCAL_STORAGE_KEY = 'auth';
export const SESSION_TIMEOUT_MESSAGE = 'Your session has timed out. Please log back in.';
export const SESSION_FLASH_MESSAGE_KEY = 'sessionFlash';
export const SESSION_PREV_REDIRECT_LOCAL_STORAGE_KEY = 'previousRedirectUrl';
export const SESSION_REDIRECT_LOCAL_STORAGE_KEY = 'redirectUrl';

class Auth {
    redirectUri = `${isBrowser() ? window.location.origin : ''}/auth/callback`;

    loginIframeUrl = `${isBrowser() ? window.location.origin : ''}/auth/login`;

    postLoginUrl = `${API_URL}/auth/login`;

    registerIframeUrl = `${isBrowser() ? window.location.origin : ''}/auth/register`;

    postRegisterUrl = `${API_URL}/auth/register`;

    accountUrl = '/account';

    authVariables: AuthPostVariables | null = null;

    /** Generate oAuth tokens to pass to our api and store them locally. */
    generateAuthVariables = () => {
        const state = this.createRandomString(40);
        const verifier = this.createRandomString(128);
        const { redirectUri, loginIframeUrl, registerIframeUrl } = this;

        // We do the challenge encryption on the api
        const authVariables: AuthPostVariables = {
            state,
            verifier,
            redirectUri,
            loginIframeUrl,
            registerIframeUrl,
        };

        localStorageSet('authVariables', authVariables);

        this.authVariables = authVariables;
        return authVariables;
    };

    setAuthedBeforeCookie = () => setCookie('authed_before', true, 60);

    /** setting auth tokens and setting a cookie to say we've been authed before */
    setAuthTokens = (tokenData: AuthToken) => {
        /** Remove any generated variables or session time out flash message. */
        localStorageRemove('authVariables');
        deleteFlashMessage(SESSION_FLASH_MESSAGE_KEY);

        setPatientUuidInStorage(tokenData);

        /** Set the cookie to say we've been logged in before and set the auth tokens. */
        this.setAuthedBeforeCookie();
        localStorageSet(SESSION_TOKEN_LOCAL_STORAGE_KEY, tokenData);
    };

    /** Deleting auth tokens */
    deleteAuthTokens = () => {
        localStorageRemove(SESSION_TOKEN_LOCAL_STORAGE_KEY);
        deletePatientUuidFromStorage();
        masterAnswersLookup.clearStorage();
    };

    clearLocalStorage = () => {
        localStorageClearItems(['consultationPanels', 'basket', 'last_seen']);
    };

    /** force logout. */
    logout = async () =>
        authService.logout().then(() => {
            this.clearLocalStorage();
            this.deleteAuthTokens();
        });

    /** Handle generic login from the login page. Not every sign in. */
    handleLogin = () => {
        const redirectUrl = localStorageGet(SESSION_REDIRECT_LOCAL_STORAGE_KEY);

        if (redirectUrl) {
            localStorageSet(SESSION_PREV_REDIRECT_LOCAL_STORAGE_KEY, redirectUrl);
            localStorageRemove(SESSION_REDIRECT_LOCAL_STORAGE_KEY);
            window.location.href = redirectUrl;
        } else {
            // We add location.search to retain any tracking params.
            window.location.href = `${this.accountUrl}${window.location.search}`;
        }
    };

    storeRedirectForAfterLogin = (url: string) => {
        localStorageSet(SESSION_REDIRECT_LOCAL_STORAGE_KEY, url);
    };

    /** Tries to grab the refresh token and get a new access token */
    refreshToken = async () => {
        const authTokens = localStorageGet(SESSION_TOKEN_LOCAL_STORAGE_KEY);
        const refreshToken = authTokens ? authTokens.refresh_token : undefined;

        if (!refreshToken) {
            return Promise.reject(new Error('No refresh token exists.'));
        }

        return authService
            .refreshToken(refreshToken)
            .then((tokenRefreshResponse) => {
                const tokenData = tokenRefreshResponse.data;

                this.setAuthTokens(tokenData);

                return Promise.resolve(tokenData);
            })
            .catch(() => {
                this.onRefreshTokenFailure();

                return Promise.reject(new Error('Error with refreshing token'));
            });
    };

    /** because we use this in a couple of places we need it to be here. */
    onRefreshTokenFailure = async () => {
        /** if it fails in here delete tokens */
        this.clearLocalStorage();
        this.deleteAuthTokens();
        setFlashMessage(SESSION_TIMEOUT_MESSAGE, SESSION_FLASH_MESSAGE_KEY);

        this.storeRedirectForAfterLogin(window.location.href);

        // This has to be a full browser refresh as if this fails axios-auth-refresh will have been triggered
        // and will withhold all api calls
        window.location.reload();
    };

    navigateToRegisterPage = () => sendMessageToParentFrame(MESSAGE_REGISTER);

    navigateToContactUsPage = () => sendMessageToParentFrame(MESSAGE_CONTACT);

    /** On the call back url being called from the api, compare details to ensure they are the same and then  */
    callback = () => {
        const { code, state } = getQueryStringObj() as {
            code: string;
            state: string;
        };

        const authVariables = localStorageGet('authVariables');

        if (code && state) {
            if (state === get(authVariables, 'state')) {
                const params = {
                    grant_type: 'authorization_code',
                    client_id: '8',
                    redirect_uri: this.redirectUri,
                    code_verifier: authVariables.verifier,
                    code,
                };

                authService
                    .getToken(params)
                    .then((resp) => {
                        const tokenData = resp.data;
                        this.setAuthTokens(tokenData);

                        sendMessageToParentFrame(MESSAGE_SUCCESSFUL_SIGN_IN, tokenData, true);
                    })
                    .catch((e) => {
                        console.dir(e);
                    });
            } else {
                window.location.href = '/auth/login?message="There was an issue with your login attempt. Please try again"'; // redirect iframe back to login
            }
        }
    };

    createRandomString = (num: number) => [...Array(num)].map(() => Math.random().toString(36)[2]).join('');

    checkLoggedIn = () => checkLoggedIn();
    getLoginInfo = () => getLoginInfo();
}

const auth = new Auth();

export default auth;
